  /* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
function initialise($){
  var padding = $('.banner').css('width'); 

  if(padding === 0){ padding = 256; }

  slideout = new Slideout({
    'panel':      $('#panel')[0],
    'menu':       $('#header')[0],
    'padding':    padding,
    'tolerance':  70,
    'touch':      false
  });

  $('#panel').css('paddingTop', $('.touch-nav').height());

  $('.js-slideout-toggle').on('click', function() {
    slideout.toggle();
  });

  $('#header, #panel').on("swipeleft", function(event){
    if( slideout.isOpen() ){
      slideout.close();
    }
  });

  $(window).on("orientationchange", function(){
    slideout.destroy(); 
    initialise();
  });
}
(function($) {
  // Use this variable to set up the common and page specific functions. If you 
  // rename this variable, you will also need to rename the namespace below.
  var MediaOrbTheme = {
    // All pages
    'common': {
      init: function() {
        if (Modernizr.touchevents) { 
          $('.css-touch-scroll').each(function(){
            var instance = $(this);
          });
        }
      }, 
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'slideout': {
      init: function() { 
        if (Modernizr.touchevents /* && $(window).width() <= 960)*/) { 
          var slideout;

          initialise($);
          $('#wpadminbar').hide();

          $('html').attr('style', 'margin-top: 0px !important');
          
          $('.content-info').find('.widget').each(function(){
            var widget = $(this);
            if(widget.find('.heading').length > 0){
              var heading = widget.find('.heading')[0].outerHTML;
              var content = widget.find('.widget-inner');
              content.find('.heading').remove();
              content.addClass('collapse').collapse('hide');
              content.on('hide.bs.collapse', function () {
                widget.removeClass('open');
              });
              content.on('show.bs.collapse', function () {
                widget.addClass('open');
              });
              widget.prepend(heading);
              widget.find('.heading').on("touch, click", function(){
                content.collapse('toggle');
              });
            }
          });

        } else {
          
          $('.nav-primary .has-collapse').on('mouseenter', function(){
            $(this).addClass('open');
            $(this).find('.collapse').collapse('show');
          });

          $('.nav-primary .has-collapse').on('mouseleave', function(){
            $(this).removeClass('open');
            $(this).find('.collapse').collapse('hide');
          });

        }
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'page_template_template_contact': {
      init: function() {
        jQuery(document).ready(function($){
          //GMAP
          var map;
          var postcode = encodeURI($('#map-canvas').data('postcode')).replace(/%20/g, "+");
          var company = encodeURI($('#map-canvas').data('company')).replace(/%20/g, "+");
          var title = $('#map-canvas').data('company');
          var zoom = 12;
          if ($("#map-canvas").attr('data-zoom')) {
            zoom = $('#map-canvas').data('zoom');
          }
          function initialize() {
            var mapOptions = {
              zoom: zoom,
              center: new google.maps.LatLng(-34.397, 150.644),
              mapTypeId: google.maps.MapTypeId.ROADMAP
            };
            var geolocate = function(address, callback) {
              jQuery.ajax({
                url: "http://maps.googleapis.com/maps/api/geocode/json",
                data: {
                  "sensor": true,
                  "address": address
                },
                dataType: "json",
                success: function(d) {
                  if (d.status === "ZERO_RESULTS") { 
                    callback(false); 
                  } else if (d.results && d.results[0] && d.results[0].geometry) {
                    callback({
                        "center": d.results[0].geometry.location
                    });
                  } else { 
                    callback(false);
                  }
                }
              });
            };
            if(geolocate) {
              map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);
              geolocate(company+","+postcode+",+United+Kingdom", function(c) {
                map.setCenter(new google.maps.LatLng(c.center.lat, c.center.lng));
                var marker = new google.maps.Marker({
                  position: new google.maps.LatLng(c.center.lat, c.center.lng),
                  map: map,
                  title: title
                });
              });
            }
          }
          google.maps.event.addDomListener(window, 'load', initialize);
        });

        //Contact form
        jQuery('#MOContactForm .error-message').hide();
        jQuery('#MOContactForm .adam-nabber').hide();
        jQuery('input[name="name"]').attr('name','inputName');

        function MO_isValidEmailAddress(emailAddress) {
            var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
            return pattern.test(emailAddress);
        }

        jQuery('input').change(function(){jQuery('#MOContactForm .error-message').hide();jQuery(this).parent().parent('.form-group').removeClass('error');});

        function MO_validateContactForm() {
          var valid_email = new MO_isValidEmailAddress(jQuery('#MOContactForm #inputEmail').val());
          if(jQuery('#MOContactForm #inputName').val().length < 3) {
            jQuery('#MOContactForm .error-message').show().html('You must enter a valid name');
            jQuery('#MOContactForm #inputName').parent().parent('.form-group').addClass('error');
            return false;
          }
          else if(!valid_email) {
            jQuery('#MOContactForm .error-message').show().html('You must enter a valid email address');
            jQuery('#MOContactForm #inputEmail').parent().parent('.form-group').addClass('error');
            return false;
          }
          else if(jQuery('#MOContactForm #inputMessage').val().length < 10) {
            jQuery('#MOContactForm .error-message').show().html('You must enter a valid message');
            jQuery('#MOContactForm #inputMessage').parent().parent('.form-group').addClass('error');
            return false;
          } else { return true; }
        }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = MediaOrbTheme;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
